import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

import HelloController from "../controllers/hello_controller"
import NewRegistrationController from "../controllers/new_registration_controller"

Stimulus.register("hello", HelloController)
Stimulus.register("new_registration", NewRegistrationController)

export { application }
